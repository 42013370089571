import React, {useState, useEffect, useContext, useRef} from 'react'
import {Route, Switch, Redirect} from 'react-router-dom'

import Context from './context.jsx'

import PrivateRoute from '../shared/auth/privateRoute.jsx'

import Header from './header.jsx'
import Footer from './footer.jsx'
import OffersPage from './offersPage.jsx'
import OfferPage from './offerPage.jsx'
import AgePage from './agePage.jsx'
import LocationPage from './locationPage.jsx'
import BalancePage from './balancePage.jsx'
import AccountPage from './accountPage.jsx'
import LoginPage from './loginPage.jsx'
import usePrerenderDetection from '../../hooks/usePrerenderDetection'

export default function App() {
  const {authenticated} = useContext(Context)

  const [parallaxImage, setParallaxImage] = useState(false)
  const [parallaxImageCover, setParallaxImageCover] = useState(false)
  const [parallaxImageBlur, setParallaxImageBlur] = useState(false)
  const [parallaxInnerContent, setParallaxInnerContent] = useState(false)

  const {browserIsPrerender, setPrerenderStatusCode} = usePrerenderDetection()

  const parallaxElement = useRef()

  useEffect(() => {
    parallaxElement.current.scroll(0, 0)
  }, [location.pathname])

  return (
    <>
      <Header />

      <div
        ref={parallaxElement}
        className={`h-full font-primary${parallaxImage ? ' parallax' : ''}`}>
        {parallaxImage ? (
          <div className='parallax-layer-background absolute pt-8 inset-0 left-negative-6px'>
            <div className='h-1/2 flex items-center justify-center'>
              <div
                className={`${
                  parallaxImageBlur ? 'filter blur-lg ' : ''
                }absolute w-full h-full bg-cover bg-center bg-no-repeat${
                  parallaxImageCover ? '' : ' bg-85-percent md:bg-50-percent'
                }`}
                style={{backgroundImage: `url(${parallaxImage})`}}></div>

              {parallaxInnerContent ? parallaxInnerContent : <></>}
            </div>
          </div>
        ) : (
          <></>
        )}

        <div
          className={`flex flex-col${
            parallaxImage
              ? ' parallax-layer-content absolute h-1/2 top-1/2 right-0 bottom-0 left-0'
              : ' min-h-screen'
          }`}>
          <div
            style={{
              boxShadow:
                '0 -1px 3px 0 rgb(0 0 0 / 0.1), 0 -1px 2px -1px rgb(0 0 0 / 0.1)',
            }}
            className='flex-grow bg-white'>
            <Switch>
              <Route exact path='/' render={() => <OffersPage />} />
              <Route path='/login' render={() => <LoginPage />} />
              <Route
                path='/offer/:id'
                render={() => <OfferPage setParallaxImage={setParallaxImage} />}
              />

              <Route path='/age' render={() => <AgePage />} />
              <Route path='/location' render={() => <LocationPage />} />

              <PrivateRoute
                allowIf={authenticated}
                redirectPath='/'
                path='/account'
                render={() => <AccountPage />}
              />
              <PrivateRoute
                allowIf={authenticated}
                redirectPath='/'
                path='/balance'
                render={() => (
                  <BalancePage
                    setParallaxImage={setParallaxImage}
                    setParallaxImageCover={setParallaxImageCover}
                    setParallaxImageBlur={setParallaxImageBlur}
                    setParallaxInnerContent={setParallaxInnerContent}
                  />
                )}
              />

              {/* <Route path='/faqs' render={props => <FaqsPage />} /> */}

              <Route
                path='*'
                render={() => {
                  setPrerenderStatusCode(404)
                  return <Redirect to='/' />
                }}
              />
            </Switch>
          </div>

          <Footer />
        </div>
      </div>
    </>
  )
}
