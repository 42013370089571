import React from 'react';
import { Route, Redirect } from 'react-router-dom';



const PrivateRoute = ( { render, allowIf, redirectPath, ...rest } ) => {
	return (
		<Route { ...rest } render={ props => (
			allowIf === true ?
				render( props )
			:
				<Redirect to={ redirectPath } />
		) } />
	);
}

export default PrivateRoute;