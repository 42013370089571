import React, {useContext} from 'react'
import {Link} from 'react-router-dom'

import hexColorIsLight from '../../assets/functions/hexColorIsLight'

import Context from './context.jsx'

export default function () {
  const {brand} = useContext(Context)
  const today = new Date()

  let footerTextColor = 'text-light'
  if (brand)
    footerTextColor = hexColorIsLight(brand.background_accent_color)
      ? 'text-dark'
      : 'text-light'

  return (
    <footer
      className={`bg-background-accent-color w-full md:py-16 px-4 ${footerTextColor}`}>
      <div className='container mx-auto text-center'>
        <div className='py-4 text-xs'>
          {/* <Link to="/faqs" className="uppercase hover:text-primary-light">
                    faq
                </Link>
                <span> &nbsp;|&nbsp; </span> */}
          <a
            href='https://bybe.com/terms-and-conditions.html'
            target='_blank'
            className='py-3 transition duration-300'>
            Terms & Conditions
          </a>
        </div>

        <div className='py-4 text-xs'>
          All rights reserved &copy;{today.getFullYear()} BYBE, INC
        </div>
      </div>
    </footer>
  )
}
