import React from 'react'

import LoginForm from './loginForm'

export default function LoginPage() {
  return (
    <div className='container py-24 md:py-30 md:w-1/2 mx-auto'>
      <LoginForm />
    </div>
  )
}
