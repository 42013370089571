import React, {useState, useContext, useEffect} from 'react'
import LoadingSpinner from '../shared/utility/loadingSpinner'

import Context from './context'

import PhoneVerifyModal from './phoneVerifyModal'

export default function AccountPage() {
  const {consumer, loadingConsumer, buttonTextColor} = useContext(Context)

  const [email, setEmail] = useState('')
  const [phone, setPhone] = useState('')

  const [phoneVerifyModalOpen, setPhoneVerifyModalOpen] = useState(false)

  useEffect(() => {
    setEmail(consumer.email || '')
    setPhone(consumer.phone || '')
  }, [consumer])

  return (
    <div className='container w-full lgplus:w-3/5 mx-auto py-24 px-4 text-center'>
      <h1 className='mb-4 text-3xl md:text-4xl font-bold'>Account</h1>

      {consumer && !loadingConsumer ? (
        <fieldset className='p-4 border border-solid border-light'>
          {/* <legend className="text-2xl uppercase font-semibold">Account</legend> */}

          <div className='inline-block w-full md:w-1/2 p-4'>
            <label
              className='block text-sm font-semibold text-left'
              htmlFor='email'>
              Email
            </label>
            <input
              className='w-full py-1 border-b border-secondary bg-white text-gray-400 cursor-not-allowed focus:outline-none'
              value={email}
              onChange={(event) => setEmail(event.target.value)}
              type='text'
              id='email'
              disabled
            />
          </div>

          {consumer.phone ? (
            <div className='inline-block w-full md:w-1/2 p-4'>
              <label
                className='block text-sm font-semibold text-left'
                htmlFor='phone'>
                Phone
              </label>
              <input
                className='w-full py-1 border-b border-secondary bg-white text-gray-400 cursor-not-allowed focus:outline-none'
                value={phone}
                onChange={(event) => setPhone(event.target.value)}
                type='number'
                id='phone'
                disabled
              />
            </div>
          ) : (
            <></>
          )}

          {consumer.phone ? (
            <p className='mt-4'>
              Contact{' '}
              <a className='font-semibold' href='mailto:support@bybeapp.com'>
                support@bybeapp.com
              </a>{' '}
              to update your account information
            </p>
          ) : (
            <button
              onClick={() => setPhoneVerifyModalOpen(true)}
              className={`block w-full sm:w-64 mx-auto mt-4 p-2 rounded-full transition duration-300 bg-primary text-center ${buttonTextColor} hover:shadow`}>
              Add Phone Number
            </button>
          )}

          {consumer.disabled ? (
            <p>
              Your Account has been disabled, If you believe this is a mistake
              please contact{' '}
              <a className='font-semibold' href='mailto:support@bybeapp.com'>
                support@bybeapp.com
              </a>{' '}
            </p>
          ) : (
            ''
          )}
        </fieldset>
      ) : (
        <LoadingSpinner
          size='12'
          color='text-primary'
          extraClasses='mx-auto'
          wrapperClasses='py-3'
        />
      )}

      <PhoneVerifyModal
        open={phoneVerifyModalOpen}
        close={() => setPhoneVerifyModalOpen(false)}
      />
    </div>
  )
}
