import React, {useContext} from 'react'

import Context from './context'

import LoadingSpinner from '../shared/utility/loadingSpinner'

export default function AgePage() {
  const {age, handleAtLeast21, handleNot21, geoPosition, buttonTextColor} =
    useContext(Context)

  return (
    <div className='container flex flex-col mx-auto justify-center items-center min-h-screen px-4 text-center'>
      {age === 'not21' ? (
        <p className='w-full inline-block mt-4 text-red-600'>
          You must be at least 21 years old to use this website.
        </p>
      ) : geoPosition ? (
        <div className='w-full'>
          <h1 className='w-full mb-4 text-4xl font-bold'>
            Are you at least 21?
          </h1>

          <button
            className={`px-4 py-3 m-2 border border-light text-lg rounded-full hover:bg-primary hover:${buttonTextColor} hover:border-primary'`}
            onClick={handleAtLeast21}>
            Yes
          </button>

          <button
            className={`px-4 py-3 border border-light text-lg rounded-full hover:bg-primary hover:${buttonTextColor} hover:border-primary'`}
            onClick={handleNot21}>
            No
          </button>
        </div>
      ) : (
        <LoadingSpinner
          size='12'
          color='text-primary'
          extraClasses='mx-auto'
          wrapperClasses='py-3'
        />
      )}
    </div>
  )
}
